.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f8;
  font-family: 'Inter', sans-serif;
  
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    text-align: center;
  
    &_title {
      font-size: 80px;
    }
  
    &_subtitle {
      color: #929292;
      font-size: 35px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: capitalize;
    }
  
    &_info {
      color: #bcbcbc;
      font-size: 25px;
      font-weight: 400;
      letter-spacing: 1px;
    }
    
    &_subinfo {
      font-size: 16px;
    }
  }
  
  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    row-gap: 10px;
  
    @media (min-width: 475px) {
      flex-direction: row;
      column-gap: 10px;
    }
  }
  
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 150px;
    height: 40px;
    transition: color .3s;
    border: 2px solid #202025;
    border-radius: 4px;
    background: transparent;
    color: #f7fbfa;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
  
    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 110%;
      height: 110%;
      background-color: #202025;
      content: '';
      z-index: -1;
    }

    &::after {
      position: absolute;
      top: 100%;
      width: 100%;
      height: 100%;
      transition: all .3s;
      border-radius: 50%;
      background-color: #f7fbfa;
      content: '';
      z-index: -1;
    }
  
    &:hover {
      color: #202025;
    }
  
    &:hover {
      &::after {
        top: -50%;
        width: 200%;
        height: 200%;
      }
    }
  }
}
    