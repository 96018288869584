.admin {
    // margin: 2rem 0;

    &__errorMessage {
      background-color: #f92e3e;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 13px 0;
      position: fixed;
      top: 75px;
      width: 100%;
      z-index: 100;
      box-sizing: border-box;
  
      @media (min-width: 768px) {
        padding: 13px 20px;
        font-size: 16px;
      }
    }

    &__successMessage {
      background-color: #24d727;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 13px 0;
      position: fixed;
      top: 75px;
      width: 100%;
      z-index: 100;
      box-sizing: border-box;
  
      @media (min-width: 768px) {
        padding: 13px 20px;
        font-size: 16px;
      }
    }
  
    &__container {
      max-width: 1470px;
      margin: 0 auto;
      padding: 0 15px;
    }
  
    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }
  
    &__title {
      font-size: 48px;
      font-weight: 800;
    }

    &__headerBtns {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 520px){
      flex-direction: row;
      align-self: flex-end;
      }
    }
  
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: max-content;
      padding: 0.6rem;
      transition: color .3s;
      border: 2px solid #202025;
      border-radius: 4px;
      background: transparent;
      color: #f7fbfa;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      overflow: hidden;
      z-index: 1;
  
      &::before {
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110%;
        height: 110%;
        background-color: #202025;
        content: '';
        z-index: -1;
      }
  
      &::after {
        position: absolute;
        top: 100%;
        width: 100%;
        height: 100%;
        transition: all .3s;
        border-radius: 50%;
        background-color: #f7fbfa;
        content: '';
        z-index: -1;
      }
  
      &:hover {
        color: #202025;
      }
  
      &:hover {
        &::after {
          top: -50%;
          width: 200%;
          height: 200%;
        }
      }
    }
  
    &__table {
      padding: 10px;
      border: 1px solid #555;
      border-radius: 13px;
    }
  }
  
  .table {
    display: none;
    @media (min-width: 768px){
    display: grid;
    align-items: center;
    grid-template-columns: auto 4fr 2fr 2fr 2fr 1fr 3fr;
    gap: 10px;
    background: #202025;
    color: #f7fbfa;
    text-align: center;
  
    &__cell {
      position: relative;
      font-weight: 800;
      text-align: center;
      text-transform: uppercase;
  
      &:first-child {
        width: 130px;
      }
  
      &:not(:first-child)::before {
        position: absolute;
        top: 0;
        left: -5px;
        width: 2px;
        height: 100%;
        background: #f7fbfa;
        content: '';
      }
    }
  }
  }