$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;

.cart {
  padding: 7rem 0 4rem;

  &__container {
    padding: 0 15px;
    margin: 0 auto;
    min-width: 320px;
    max-width: 1470px;
    box-sizing: border-box;
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 0 60%;
    gap: 1.5rem;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    background-color: $color-grey-light;
    color: $color-dark;
    box-shadow: 0 3px 15px rgba(0, 0, 0, .7);
    overflow: hidden;

    @media (min-width: 768px) {
      padding: 1.5rem;
    }
  }

  &__empty {
    font-size: 1.2rem;
  }

  &__aside {
    flex: 1 0 30%
  }
}

.aside {
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 4.5rem;
  padding: 1rem;
  margin: 0 auto;
  width: 100%;
  max-width: 350px;
  // border: 2px solid $color-dark;
  border-radius: 1rem;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;

  &__title {
    font-size: 1.5rem;
  }

  &__text {
    font-size: .8rem;
    line-height: 1.2rem;
  }

  &__price,
  &__totalPrice {
    display: flex;
    flex-wrap: wrap;
    gap: .63rem;
    align-items: flex-end;
    justify-content: space-between;
    font-weight: 700;

    &_title {
      font-weight: 400;
    }
  }

  &__price {
    font-size: 1.2rem;

    &_title {
      font-size: 1rem;
    }
  }

  &__totalPrice {
    font-size: 1.5rem;

    &_title {
      font-size: 1.2rem;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .63rem;
    position: relative;
    padding: 7px 30px;
    transition: .3s;
    border: 2px solid #202025;
    border-radius: 50px;
    background-color: #202025;
    color: #f7fbfa;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &:disabled {
      background: $color-grey;
      pointer-events: none;
    }

    @media (min-width: 420px) {
      font-size: 1rem;
    }

    &:has(> svg) {
      padding: 7px 10px;
    }

    & svg {
      width: 15px;
      height: 15px;

      &>path {
        transition: stroke .3s, fill .3s;
      }
    }

    &:hover>svg>path {
      stroke: #202025;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: -20%;
      width: 150%;
      height: 50%;
      transition: all .3s;
      border-radius: 50%;
      content: '';
      z-index: -1;
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 150%;
      height: 150%;
      content: '';
      z-index: -1;
    }

    &::after {
      background-color: #f7fbfa;
      z-index: -1;
    }

    &:hover {
      color: #202025;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 150%;
        height: 200%;
      }
    }
  }
}