$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;
$color-accent: #f92e3e;

.order {
  padding: 7rem 0 4rem;

  &__container {
    padding: 0 15px;
    margin: 0 auto;
    min-width: 320px;
    max-width: 1470px;
    box-sizing: border-box;
  }

  &__content>&__title {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  &__content &__totalPrice {
    font-size: 1.5rem;
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }

  &__subtitle {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    background-color: $color-grey-light;
    color: $color-dark;
    box-shadow: 0 3px 15px rgba(0, 0, 0, .7);

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__aside {
    flex: 1 0 35%;
    order: 2;

    @media (min-width: 768px) {
      order: unset;
    }
  }

  &__form {
    flex: 0 1 60%;
    width: 100%;
    order: -1;
    padding: 1.5rem 1rem;
    background-color: $color-light;
    border-radius: 1rem;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
    box-sizing: border-box;

    @media (min-width: 768px) {
      order: unset;
    }

    form {
      max-width: 100%;

      label:has(+input[type='checkbox']),
      label:has(+input[type='radio']) {
        &::selection {
          background: none;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          transition: all .3s;
        }
      }

      label:has(+input[type='checkbox']) {
        position: relative;
        padding: 5px 0 5px 55px;
        width: max-content;
        margin-bottom: 1.5rem;
        cursor: pointer;

        &:before {
          top: 0;
          left: 0;
          width: 50px;
          height: 26px;
          border-radius: 13px;
          background: $color-grey-light;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
        }

        &:after {
          top: 1px;
          left: 2px;
          width: 22px;
          height: 22px;
          border-radius: 10px;
          background: $color-light;
          box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
        }
      }

      label:has(+input[type='checkbox']:checked) {
        &:before {
          background: $color-accent;
        }

        &:after {
          left: 26px;
        }
      }

      label:has(+input[type='radio']) {
        position: relative;
        padding-left: 55px;
        line-height: 2.5rem;
        width: max-content;
        margin-bottom: 1.5rem;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 2.5rem;
        }

        &:has(+input[type='radio']:checked)::before {
          background: $color-accent;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          border-radius: 50%;
        }

        &:before {
          top: 0;
          left: 0;
          width: 2.5rem;
          height: 2.5rem;
          background: $color-grey-light;
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
        }

        &:hover::before {
          background: $color-grey;
        }

        &:after {
          top: calc(2.5rem / 2);
          left: calc(2.5rem / 2);
          transform: translate(-50%, -50%);
          width: 1.5rem;
          height: 1.5rem;
          background: $color-light;
          box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
        }

        &:active::after {
          box-shadow: inset 0 2px 3px rgba(0, 0, 0, .4);
        }
      }

      input,
      label+div>input {
        margin-bottom: 1rem;
        border: none;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);

        &[type='checkbox'],
        &[type='radio'] {
          width: 0;
          position: absolute;
          z-index: -1;
          opacity: 0;
        }
      }

      label+div>input {
        margin-bottom: 0;
      }

      div:has(>input) {
        margin-bottom: 2rem;
      }

      button {
        border: 2px solid $color-dark;
        background-color: $color-dark;
        color: $color-light;
        z-index: 1;

        &::after {
          background-color: $color-light;
        }

        &::before {
          background-color: $color-dark;
        }

        &:hover {
          color: $color-dark;
        }
      }

      @media (min-width: 1024px) {

        input {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.aside {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  &__title {
    font-size: 1.2rem;
  }

  &__totalPrice {
    font-size: 1.5rem;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem 1rem;
    padding: 1rem;
    background-color: $color-light;
    border-radius: 1rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
  }

  &__text {
    font-size: .8rem;
    line-height: 1.2rem;

    &_name {
      width: 100%;
      font-size: 1.1rem;
      font-weight: 700;
      line-height: 1.2rem;
    }

    &_amount {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .63rem;
    padding: 7px 30px;
    width: max-content;
    transition: .3s;
    border: 2px solid $color-dark;
    border-radius: 4px;
    // background-color: $color-dark;
    background-color: $color-light;
    // color: $color-light;
    color: $color-dark;
    text-align: center;
    font-size: .75rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &:disabled {
      background: $color-grey;
      pointer-events: none;
    }

    @media (min-width: 768px) {
      font-size: 1rem;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: -20%;
      width: 150%;
      height: 50%;
      transition: all .3s;
      border-radius: 50%;
      content: '';
      z-index: -1;
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 150%;
      height: 150%;
      content: '';
      z-index: -1;
    }

    &::after {
      background-color: $color-dark;
      z-index: -1;
    }

    &:hover {
      color: $color-light;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 150%;
        height: 200%;
      }
    }
  }
}

html:has(.invoice),
body:has(.invoice) {
  overflow: hidden;
}

.invoice {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 50;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1.5rem 1rem;
    background-color: $color-light;
    border-radius: 1rem;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
  }

  &__text {
    font-size: 1.2rem;
    text-align: center;
  }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .63rem;
    padding: 7px 30px;
    width: max-content;
    transition: .3s;
    border: 2px solid $color-dark;
    border-radius: 4px;
    color: $color-light;
    text-align: center;
    font-size: .75rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &:disabled {
      background: $color-grey;
      pointer-events: none;
    }

    @media (min-width: 768px) {
      font-size: 1rem;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: -20%;
      width: 150%;
      height: 50%;
      transition: all .3s;
      border-radius: 50%;
      content: '';
      z-index: -1;
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 150%;
      height: 150%;
      background-color: $color-dark;
      content: '';
      z-index: -1;
    }

    &::after {
      background-color: $color-light;
      z-index: -1;
    }

    &:hover {
      color: $color-dark;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 150%;
        height: 200%;
      }
    }
  }
}