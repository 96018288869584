$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-accent: #f92e3e;

.addToCart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
  margin: 0 auto;
  padding: 7px 10px;
  transition: .3s;
  border: 2px solid $color-dark;
  border-radius: 50px;
  background-color: $color-dark;
  color: $color-light;
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  &_added {
    background-color: $color-grey;

    svg {
      transform: rotate(180deg);
    }

    &:hover>svg>path {
      fill: $color-dark;
    }
  }

  &.productRow {
    @media (min-width: 768px) {
      max-width: max-content;
      justify-self: center;
      font-size: .9rem;
    }
  }

  @media (min-width: 420px) {
    font-size: 1rem;
  }

  & svg {
    width: 15px;
    height: 15px;

    .productRow & {
      @media (min-width: 768px) {
        width: initial;
        height: initial;
      }
    }

    &>path {
      transition: stroke .3s, fill .3s;
    }
  }

  &:hover>svg>path {
    stroke: $color-dark;
  }

  &::after {
    position: absolute;
    top: 100%;
    left: -20%;
    width: 150%;
    height: 50%;
    transition: all .3s;
    border-radius: 50%;
    content: '';
    z-index: -1;
  }

  &::before {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 150%;
    height: 150%;
    content: '';
    z-index: -1;
  }

  &::after {
    background-color: $color-light;
    z-index: -1;
  }

  &:hover {
    color: $color-dark;
  }

  &:hover {
    &::after {
      top: -50%;
      width: 150%;
      height: 200%;
    }
  }
}

.outOfStock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  position: relative;
  margin: 0 auto;
  padding: 7px 10px;
  transition: .3s;
  border: 2px solid $color-dark;
  border-radius: 50px;
  background-color: $color-light;
  color: $color-accent;
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  &.productRow {
    @media (min-width: 768px) {
      max-width: max-content;
      justify-self: center;
      font-size: .9rem;
    }
  }

  @media (min-width: 420px) {
    font-size: 1rem;
  }
}

.loading {
  animation: pressBtn .5s linear infinite;
}

@keyframes pressBtn {
  0% {
    scale: 1;
  }

  50% {
    scale: .9;
  }

  100% {
    scale: 1;
  }
}