$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;

.productCard {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: .63rem;
  padding: .63rem;
  // border: 2px solid $color-dark;
  border-radius: 1rem;
  background-color: $color-light;
  color: $color-dark;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, .4);
  overflow: hidden;
  transition: box-shadow .3s;

  &.cart,
  &.order {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    padding: 1rem;
    background: $color-light;
    border: none;
    overflow: initial;

    @media (min-width: 450px) {
      grid-template-columns: 30px 30px 2fr 2fr 2fr;
    }

    @media (min-width: 768px) {
      grid-template-columns: 50px 50px 2fr 2fr 2fr;
    }

    &:not(:last-child) {
      margin-bottom: -2px;
    }
  }

  &.cart,
  &.order {
    @media (min-width: 1270px) {
      display: flex;
      flex-direction: row;
    }
  }

  &.productRow {
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      // box-shadow: none;

      &:not(:last-child) {
        margin-bottom: -2px;
      }
    }
  }

  &.order {
    @media (min-width: 768px) {
      box-shadow: 1px 5px 15px rgba(0, 0, 0, 0.4);
    }
  }

  &__mainLink {
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    // margin: -.63rem -.63rem auto -.63rem;

    .productRow & {
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        width: 290px;
        // max-height: 100px;
        // max-height: 120px;
        // margin: -.63rem 0 -.63rem -.63rem;
        overflow: hidden;
      }

      @media (min-width: 1270px) {
        width: 350px;
      }
    }

    .cart &,
    .order & {
      grid-area: 1 / 1 / 2 / 2;
      justify-self: center;
      max-width: 100%;
      // margin: 0;

      @media (min-width: 450px) {
        grid-area: 1 / 1 / 3 / 3;
        display: flex;
        align-items: center;
        height: initial;
        // margin: -.63rem 0 -.63rem -.63rem;
        overflow: hidden;
      }
    }

    .cart & {
      @media (min-width: 1270px) {
        width: 200px;
      }
    }

    .order & {
      grid-area: 1 / 1 / 2 / 2;

      @media (min-width: 450px) {
        grid-area: 1 / 1 / 3 / 3;
        display: flex;
        align-items: center;
        height: initial;
        overflow: hidden;
      }

      @media (min-width: 768px) {
        width: 200px;
      }
    }

    &>span {
      mix-blend-mode: multiply;
      // padding: 5px 0;
      overflow: hidden;

      .productRow & {
        @media (min-width: 768px) {
          flex: 1 0 70px;
          max-height: 100px;
          max-width: 70px;
          // padding: 0 5px;
        }
      }

      .productRow &,
      .order & {
        @media (min-width: 1024px) {
          // flex: 1 0 70px;
          // max-width: initial;
          // padding: 0 5px;
        }
      }

      .cart &,
      .order & {
        // padding: initial;
        max-height: 100px;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.7);
  }

  &__name {
    // padding: 0 .63rem;
    font-size: 1.3rem;
    line-height: 1.8rem;

    .productRow & {
      @media (min-width: 768px) {
        // padding: .63rem;
      }
    }

    .cart &,
    .order & {
      // padding: initial;
      width: 100%;
      font-size: 1.1rem;
      line-height: 1.5rem;
    }

    @media (min-width: 420px) {
      font-size: 1.1rem;
      line-height: 1.7rem;
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    &>*:not(:last-child):after {
      position: absolute;
      content: '';
      width: 3px;
      height: 3px;
      background: $color-dark;
      top: 50%;
      right: -0.5rem;
      transform: translate(50%, -50%);
      border-radius: 50%;

      .cart &,
      .order & {
        display: none;
      }
    }

    .productRow & {
      @media (min-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem .5rem;
        margin-right: auto;
        width: 120px;

        &>*:not(:last-child):after {
          display: none;
        }
      }
    }

    .cart &,
    .order & {
      grid-area: 1/2/2/4;
      align-items: flex-start;
      gap: .63rem;

      @media (min-width: 450px) {
        grid-area: 1 / 3 / 2 / 6;
      }

      @media (min-width: 768px) {
        width: 100%;
      }

    }

    .order & {
      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-start;
      }

      @media (min-width: 1024px) {
        grid-area: 1 / 3 / 3 / 5;
      }
    }

    .cart & {
      @media (min-width: 1270px) {
        align-items: center;
      }
    }
  }

  &__link {
    position: relative;
    font-size: 1rem;
    transition: color .3s;

    @media (min-width: 420px) {
      font-size: .8rem;
    }

    .cart & {
      align-self: flex-end;

      @media (min-width: 420px) {
        font-size: initial;
      }
    }

    .order & {
      align-self: flex-end;

      @media (min-width: 768px) {
        align-self: flex-end;
        // font-size: initial;
      }
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: $color-grey;
    }
  }

  &__purchase {
    .productRow & {
      @media (min-width: 768px) {
        flex: 1 0 40%;
      }
    }

    .cart &,
    .order & {
      grid-area: 2 / 1 / 3 / 4;

      @media (min-width: 450px) {
        grid-area: 2 / 3 / 3 / 6;
      }
    }

    .order & {
      grid-area: 2 / 1 / 3 / 4;

      @media (min-width: 450px) {
        grid-area: 2 / 3 / 3 / 6;
      }

      @media (min-width: 1024px) {
        grid-area: 1 / 5 / 3 / 6;
      }
    }
  }

  // &:not(:has(.productRow)) &__purchase {
  //   margin-top: auto;
  // }

  &__price {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: .2rem;
    font-size: 1rem;

    .cart &,
    .order & {
      align-self: flex-end;
    }

    &_title {
      font-size: .8rem;
    }

    .cart & {
      @media (min-width: 1270px) {
        width: 100%;
      }
    }

    .order & {
      @media (min-width: 1024px) {
        width: 100%;
      }
    }
  }

  &__amount {
    display: flex;
  }

  &__delete {
    position: absolute;
    content: '';
    right: -8px;
    top: -8px;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: $color-dark;
    cursor: pointer;
    transform: rotate(45deg);
    transition: background-color .3s;

    &:hover {
      background: #f92e3e;
    }

    &::after,
    &::before {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      width: 60%;
      height: 2px;
      background: $color-light;
      transform: translate(-50%, -50%);
    }

    &::after,
    &::before {
      top: 50%;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.purchase {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: .63rem;
  margin-top: auto;

  .cart &,
  .order & {
    justify-content: space-evenly;
    margin-top: 0;

    @media (min-width: 450px) {
      justify-content: flex-start;
    }

    @media (min-width: 1024px) {
      gap: 1rem 3rem;
    }
  }

  .cart & {
    @media (min-width: 1270px) {
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
  }

  .productRow & {
    margin-top: 0;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1.4fr;
      justify-content: center;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr 1fr 1.5fr;
    }
  }

  .order & {
    display: flex;
    justify-content: flex-start;

    @media (min-width: 1024px) {
      justify-content: space-between;
      gap: 1rem;
    }
  }

  &__quantity {
    font-size: .8rem;
  }

  &__price {
    font-size: 1.1rem;
    font-weight: 400;

    .cart &,
    .order & {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;
    }

    .productRow & {
      text-align: center;
    }

    @media (min-width: 420px) {
      font-weight: 300;
    }

    &_title {
      font-size: .8em;
      font-weight: 400;
    }

    &_total {
      flex: initial;
      width: 100%;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;

      .productRow &,
      .cart &,
      .order & {
        @media (min-width: 768px) {
          flex: initial;
        }
      }

      .cart &,
      .order & {
        width: initial;
      }

      .order & {
        width: initial;
        font-size: 1rem;
      }

      .cart & {
        @media (min-width: 420px) {
          font-size: 1.5rem;
        }
      }

      @media (min-width: 420px) {
        font-size: 1.1rem;
      }
    }

    .cart & {
      @media (min-width: 1270px) {
        width: 150px;
      }
    }
  }

  &__amount {
    margin-left: auto;
  }

  .productRow &__amount {
    @media (min-width: 768px) {
      width: 100%;
    }
  }

  &__addToCart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: relative;
    padding: 7px 30px;
    transition: .3s;
    border: 2px solid #202025;
    border-radius: 50px;
    background-color: #202025;
    color: #f7fbfa;
    font-size: 1.2rem;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &_added {
      background-color: $color-grey;

      svg {
        transform: rotate(180deg);
      }

      &:hover>svg>path {
        fill: #202025;
      }
    }

    .productRow & {
      @media (min-width: 768px) {
        max-width: max-content;
        justify-self: center;
        font-size: .9rem;
      }
    }

    @media (min-width: 420px) {
      font-size: 1rem;
    }

    &:has(> svg) {
      padding: 7px 10px;
    }

    & svg {
      width: 15px;
      height: 15px;

      .productRow & {
        @media (min-width: 768px) {
          width: initial;
          height: initial;
        }
      }

      &>path {
        transition: stroke .3s, fill .3s;
      }
    }

    &:hover>svg>path {
      stroke: #202025;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: -20%;
      width: 150%;
      height: 50%;
      transition: all .3s;
      border-radius: 50%;
      content: '';
      z-index: -1;
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 150%;
      height: 150%;
      content: '';
      z-index: -1;
    }

    &::after {
      background-color: #f7fbfa;
      z-index: -1;
    }

    &:hover {
      color: #202025;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 150%;
        height: 200%;
      }
    }
  }
}

.amount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .63rem;

  &__btn {
    position: relative;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    background: $color-dark;
    cursor: pointer;
    transition: background-color .3s;

    .cart &,
    .order & {
      width: 1.5rem;
      height: 1.5rem;
    }

    @media (min-width: 420px) {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      background: $color-grey;
    }

    &_decrease::after,
    &_increase::before,
    &_increase::after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      width: 60%;
      height: 2px;
      background: $color-light;
      transform: translate(-50%, -50%);
    }

    &_decrease::after,
    &_increase::before,
    &_increase::after {
      top: 50%;
    }

    &_increase::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &_decrease:disabled,
    &_increase:disabled {
      opacity: .5;
    }
  }

  &__input {
    width: 90px;
    font-size: 1.2rem;
    text-align: center;
    border: 2px solid $color-grey;
    border-radius: 5px;
    box-sizing: border-box;

    .cart & {
      width: 70px;
    }

    @media (min-width: 420px) {
      width: 60px;
      font-size: 1rem;
    }
  }
}

.loading {
  animation: pressBtn .5s linear infinite;
}

@keyframes pressBtn {
  0% {
    scale: 1;
  }

  50% {
    scale: .9;
  }

  100% {
    scale: 1;
  }
}