.contacts {
  height: 100%;
  padding-top: 30px;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(45deg, rgb(227, 227, 227), rgb(247, 251, 250) 60%);

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #595858;
    margin: 0 auto;
    padding: 5rem 15px 2rem;
    box-sizing: border-box;
    max-width: 900px;
  }

  &__title {
    margin-bottom: 20px;
    padding-top: 10px;
    font-size: 34px;
  }

  &__info {
    position: relative;
    font-size: 16px;
    line-height: 140%;
    padding: 20px 0;

    @media (min-width: 768px) {
      font-size: 19px;
    }

    &::before,
    &::after {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #5d5d5d;
      box-shadow: 1px 5px 10px rgba(0, 0, 0, .4);
      content: '';
    }

    &::before {
      top: 0;
    }
  }

  &__infoTitle {
    font-size: 19px;
    letter-spacing: 0.05em;
    padding-bottom: 10px;
  }

  &__infoText {
    font-size: 16px;
    padding-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }
}

@media (min-width: 768px) {
  .contacts {

    // &__container {
    //   padding: 20px 50px;
    // }

    // &__title {
    //   font-size: 50px;
    // }

    &__infoTitle {
      font-size: 29px;
      letter-spacing: 0.05em;
    }

    &__infoText {
      max-width: 100%;
      font-size: 20px;
    }

  }
}

@media (min-width: 993px) {
  .contacts {

    &__container {
      width: 980px;
      // padding: 20px 0;
    }
  }
}