$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;

body,html {
  // height: 100%;
  min-height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', Sans-Serif;
  font-size: 10px;
  overflow-x: auto;
  // overflow-y: scroll;
  
  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

#main {
  flex: 1 0 auto;
}
}

button {
  padding: 0;
  margin: 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  background: none;
}

section {
  overflow-x: hidden;
}

h2,
h3,
h4,
p,
input {
  margin: 0;
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  padding-inline-start:0;
  @media (min-width: 768px) {
  padding-inline-start: 10px;}
}

a {
  text-decoration: none;
  cursor: revert;
  color: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 12px;
  height: 100%;
  border-radius: 10px;
  background: $color-grey-light;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $color-grey;
  background-color: $color-grey-light;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-dark;
  box-shadow: inset 0 0 7px $color-grey;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $color-grey;
  box-shadow: inset 0 0 7px $color-dark;
}