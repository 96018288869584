.product-images-slider {
  margin-bottom: .63rem;

  .swiper-slide {

    img {
      width: 100%;
      height: 100%;
      max-height: 400px;
      object-fit: contain;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #5d5d5d;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }
}

.product-images-slider-thumbs {
  .swiper-slide {
    cursor: pointer;
    border: 1px solid transparent;
  }

  &-wrapper {
    display: flex;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}


.swiper-container {
  position: relative;
  // mix-blend-mode: multiply;
  mix-blend-mode: darken;
  z-index: 10;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 40%;
  z-index: 20;
  width: 100%;
}

.swiper-wrapper {
  align-items: center;
}