$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;

.productList {
  padding: 4rem 0;

  &__container {
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 1470px;
  }

  &__inner {
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    background-color: $color-grey-light;
    color: $color-dark;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
    box-sizing: border-box;

    @media (min-width: 768px) {
      padding: 1.5rem;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    // margin: 2rem 0;

    @media (min-width: 560px) {
      justify-content: space-between;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  &__controlTitle {
    font-size: .8rem;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .63rem;
    position: relative;
    padding: 0.63rem 1rem;
    transition: .3s;
    border: 2px solid $color-dark;
    border-radius: 0.25rem;
    background: $color-dark;
    color: $color-light;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    @media (min-width: 420px) {
      font-size: 1rem;
    }

    & svg {
      width: 15px;
      height: 15px;

      &>path {
        transition: fill .3s;
      }
    }

    &:hover>svg>path {
      fill: $color-dark;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: -20%;
      width: 150%;
      height: 50%;
      transition: all .3s;
      border-radius: 50%;
      content: '';
      z-index: -1;
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 150%;
      height: 150%;
      content: '';
      z-index: -1;
    }

    &::after {
      background-color: $color-light;
      z-index: -1;
    }

    &:hover {
      color: $color-dark;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 150%;
        height: 200%;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 2rem;
    padding: 2rem 0 3rem;
    margin: 0 auto;

    &:has(>.productList__empty) {
      display: block;
    }

    .productTable & {
      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        row-gap: 1.5rem;
        margin: 0 auto;
      }
    }

    @media (min-width: 516px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, 235px);
      gap: 2rem 1rem;
      justify-content: space-around;
    }
  }

  &__empty {
    font-size: 1rem;
  }
}

.empty {
  padding: 3rem;
  border-radius: 1rem;
  background-color: $color-light;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, .4);

  &__title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  &__subtitle {
    margin: 0 0 1rem 0;
    font-size: 1rem;
  }

  &__searchTips {
    margin: 0;
    padding: 0;
  }

  &__tip {
    position: relative;
    padding-left: 1rem;
    line-height: 1rem;

    &:not(:last-child) {
      margin-bottom: 0.63rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: .3rem;
      width: 0.3rem;
      height: 0.3rem;
      background: $color-dark;
      border-radius: 50%;
    }
  }
}

.controller {
  display: flex;
  align-items: center;
  gap: .5rem;

  &__title {
    font-size: .8rem;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .63rem;
    position: relative;
    padding: 7px 10px;
    transition: .3s;
    border: 2px solid $color-dark;
    border-radius: 0.25rem;
    background: $color-dark;
    color: $color-light;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &_active {
      border: 2px solid $color-dark;
      background: $color-light;
      color: $color-dark;
    }

    @media (min-width: 420px) {
      font-size: 1rem;
      padding: 0.63rem 1rem;
    }

    // &:has(> svg) {
    //   padding: 7px 10px;
    // }

    & svg {
      width: 15px;
      height: 15px;
      transition: transform .3s;

      &>path {
        transition: stroke .3s, fill .3s, transform .3s;
      }
    }

    &:hover>svg>path {
      stroke: $color-dark;
      fill: $color-dark;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: -20%;
      width: 150%;
      height: 50%;
      transition: all .3s;
      border-radius: 50%;
      content: '';
      z-index: -1;
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 150%;
      height: 150%;
      content: '';
      z-index: -1;
    }

    &::after {
      background-color: $color-light;
      z-index: -1;
    }

    &:hover {
      color: $color-dark;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 150%;
        height: 200%;
      }
    }
  }
}

.arrowUp {
  svg {
    transform: rotate(90deg);
  }
}

.arrowDown {
  svg {
    transform: rotate(270deg);
  }
}