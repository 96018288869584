$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  background-color: $color-grey-light;
  color: $color-dark;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;

  @media (min-width: 480px) {
    padding: 2rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  @media (min-width: 1024px) {
    // align-items: center;
    gap: 3rem;
  }
}

.sliderContainer {
  min-width: 260px;
  flex: 0 0 45%;
  padding: 1rem;
  border-radius: 1rem;
  background-color: $color-light;
  color: $color-dark;
  box-shadow: inset 1px 5px 15px rgba(0, 0, 0, .4);
  box-sizing: border-box;

  // @media (min-width: 480px) {
  //   width: 390px;
  // }

  @media (min-width: 768px) {
    // align-self: center;
    // max-width: 500px;
    // min-width: initial;
    margin: 0 auto;
  }
}

.product {
  flex: 1 1 40%;
  max-width: 400px;
  position: sticky;
  top: 75px;
  padding: 1.5rem 1rem;
  margin: 0 auto;
  border-radius: 1rem;
  background-color: $color-light;
  color: $color-dark;
  box-shadow: 1px 5px 15px rgba(0, 0, 0, .4);
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  @media (min-width: 1024px) {
    // max-width: initial;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    column-gap: 20px;
  }

  &__title {
    // color: #0c0938;
    font-weight: 500;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: -.6px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__item {
    position: relative;
    display: flex;
    column-gap: 20px;
    color: $color-grey;
    font-size: 13px;
    line-height: 20px;
    padding-bottom: 2rem;
    border-bottom: 1px solid $color-grey-light;
  }

  &__details {
    padding-top: 20px;
    margin-bottom: 1rem;

    &_title {
      margin-bottom: .63rem;
      font-size: 16px;
      line-height: 22px;
      // color: #0c0938;
      font-weight: 500;
    }

    &_price {
      // position: relative;
      display: inline-block;
      // color: #0c0938;
      background-color: $color-grey-light;
      padding: 1rem 1.5rem;
      border-radius: 5px;
      // width: 100px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.purchase {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  @media (min-width: 389px) {
    justify-content: flex-start;
  }

  &__price {
    text-align: center;

    @media (min-width: 389px) {
      text-align: left;
    }

    &_text {
      text-transform: uppercase;
      color: #666f80;
      font-size: 13px;
      font-weight: 500;
    }

    &_total {
      color: #202025;
      font-weight: 600;
      font-size: 1.3rem;
      white-space: nowrap;
    }
  }

  &__addToCart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 50px;
    transition: color .3s;
    border: 2px solid #202025;
    border-radius: 4px;
    background: transparent;
    color: #f7fbfa;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 1;

    &_added {
      background-color: #5d5d5d;

      svg {
        transform: rotate(180deg);
      }

      &:hover>svg>path {
        fill: #202025;
      }
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 110%;
      height: 110%;
      background-color: #202025;
      content: '';
      z-index: -1;
    }

    &::after {
      position: absolute;
      top: 100%;
      width: 100%;
      height: 100%;
      transition: all .3s;
      border-radius: 50%;
      background-color: #f7fbfa;
      content: '';
      z-index: -1;
    }

    &:hover {
      color: #202025;
    }

    &:hover>svg>path {
      stroke: #202025;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 200%;
        height: 200%;
      }
    }
  }
}

.amount {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.63rem;
  border-radius: 5px;
  // padding: 12px;

  &__btn {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #202025;
    cursor: pointer;
    transition: background-color .3s;

    @media (min-width: 748px) {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      background: #5d5d5d;
    }

    &_decrease::after,
    &_increase::before,
    &_increase::after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      width: 60%;
      height: 2px;
      background: #f7fbfa;
      transform: translate(-50%, -50%);
    }

    &_decrease::after,
    &_increase::before,
    &_increase::after {
      top: 50%;
    }

    &_increase::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &_decrease:disabled {
      opacity: .5;
    }
  }

  &__input {
    width: 85px;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 5px;
    border: 2px solid $color-dark;
    outline: none;
    box-sizing: border-box;
  }
}

.loading {
  animation: pressBtn .5s linear infinite;
}

@keyframes pressBtn {
  0% {
    scale: 1;
  }

  50% {
    scale: .9;
  }

  100% {
    scale: 1;
  }
}