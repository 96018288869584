.privacyPolicy {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(45deg, rgb(227, 227, 227), rgb(247, 251, 250) 60%);

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #595858;
    margin: 0 auto;
    padding: 5rem 15px 2rem;
    box-sizing: border-box;
    max-width: 1200px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 28px;

    @media (min-width: 768px) {
      font-size: 34px;
    }
  }

  &__text {
    position: relative;
    font-size: 12px;
    line-height: 125%;
    padding: 20px 0;

    @media (min-width: 768px) {
      font-size: 19px;
      line-height: 140%;
    }

    &::before,
    &::after {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #5d5d5d;
      box-shadow: 1px 5px 10px rgba(0, 0, 0, .4);
      content: '';
    }

    &::before {
      top: 0;
    }
  }

  &__list {
    list-style: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style-position: inside;
  }

  &__listItem {
    box-sizing: content-box;
    margin-left: 10px;
  }

  &__listItem::marker {
    font-size: 16px;
    font-weight: 700;

    @media (min-width: 768px) {
      font-size: 25px;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    display: contents;
    line-height: 120%;

    @media (min-width: 500px) {
      font-size: 20px;
    }

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  &__paragraph {
    margin: 20px 0 10px;
    padding: 0 15px;
    text-align: justify;
  }

  table {
    border-collapse: collapse;
    font-size: 11px;

    @media (min-width: 500px) {
      font-size: 12px;
    }

    @media (min-width: 768px) {
      font-size: 19px;
    }
  }

  td,
  th {
    vertical-align: top;
    border: 1px solid #000;
    padding: 1px;

    @media (min-width: 500px) {
      padding: 2px;
    }

    @media (min-width: 768px) {
      padding: 3px;
    }
  }

}