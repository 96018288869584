$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;

.rotate {
  svg {
    transform: rotate(180deg);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .63rem;
  position: relative;
  padding: 0.63rem 1rem;
  transition: .3s;
  border: 2px solid $color-dark;
  border-radius: 0.25rem;
  background: $color-dark;
  color: $color-light;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  overflow: hidden;
  z-index: 1;

  &_disabled {
    border: 2px solid $color-dark;
    background: $color-light;
    color: $color-dark;

    &>svg>path {
      fill: $color-dark;
    }
  }

  @media (min-width: 420px) {
    font-size: 1rem;
  }

  &:has(> svg) {
    padding: 7px 10px;
  }

  & svg {
    width: 15px;
    height: 15px;

    &>path {
      transition: fill .3s;
      // transition: stroke .3s;
    }
  }

  &:hover>svg>path {
    fill: $color-dark;
    // stroke: $color-dark;
  }

  &::after {
    position: absolute;
    top: 100%;
    left: -20%;
    width: 150%;
    height: 50%;
    transition: all .3s;
    border-radius: 50%;
    content: '';
    z-index: -1;
  }

  &::before {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 150%;
    height: 150%;
    content: '';
    z-index: -1;
  }

  &::after {
    background-color: $color-light;
    z-index: -1;
  }

  &:hover {
    color: $color-dark;
  }

  &:hover {
    &::after {
      top: -50%;
      width: 150%;
      height: 200%;
    }
  }
}