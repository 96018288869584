.btnEffect {
  &::before {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background-color: #202025;
    content: '';
    z-index: -1;
  }

  &::after {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 100%;
    transition: all .3s;
    border-radius: 50%;
    background-color: #f7fbfa;
    content: '';
    z-index: -1;
  }

  &:hover {
    color: #202025;
  }

  &:hover {
    &::after {
      top: -50%;
      width: 200%;
      height: 200%;
    }
  }
}

// mobile
.filter {
  padding: 7rem 0 2rem;
  background-color: rgba(0, 0, 0, .1);
  font-family: "Roboto", Sans-Serif;
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.4);

  &__container {
    margin: 0 auto;
    max-width: 1470px;
    padding: 0 15px;
  }

  &__nav {
    display: flex;
    flex-direction: column;
  }

  &__navBtnFilter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    transition: color .3s, width .3s;
    border: 2px solid #202025;
    border-radius: 4px;
    background: transparent;
    color: #f7fbfa;
    font-size: 16px;
    letter-spacing: .17em;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    box-sizing: border-box;
  }

  &__navTitle {
    display: none;
  }

  &__navContent {
    display: none;

    &.open {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 20px;
    }
  }

  &__dropdownBlock {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__dropdown {
    width: 100%;
    position: relative;
  }

  &__dropdownTitle {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  &__search input {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px 10px;
    border: 2px solid black;
    border-radius: 4px;
    font-size: 14px;

    &:focus {
      border: 2px solid #f92e3e;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    // margin-top: 5px;
  }

  &__navigationBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 45px;
    padding: .63rem 1rem;
    transition: color .3s, width .3s;
    border: 2px solid #202025;
    border-radius: 4px;
    background: transparent;
    color: #f7fbfa;
    font-size: 16px;
    letter-spacing: .17em;
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    box-sizing: border-box;
  }
  &.adminFilter {
    padding: 2rem 0;
    margin-bottom: 1rem;
    }
}

@media (min-width: 768px) {
  .filter {

    &__navBtnFilter {
      width: 150px;
      margin: 0;
    }
  }
}

@media (min-width: 768px) {
  .filter {

    &__navBtnFilter {
      display: none;
    }

    &__navTitle {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 16px;
      text-transform: uppercase;
    }

    &__navContent {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      align-items: flex-end;
      gap: 1rem;
    }

    &__dropdownBlock {
      flex-direction: row;
      // width: 70%;
      // width: calc(50% - 100px);
      // max-width: 400px;
      width: 450px;
    }

    &__search {
      // width: 100%;
      // width: 200px;
      // width: calc(50% - 100px);
      min-width: 300px;

      & input {
        font-size: 15px;
        padding: 11px 10px;
        outline: 0;
      }
    }

    &__navigation {
      // width: 250px;
      // width: 30%;
      // margin: 0;
      margin-left: auto;
      gap: 10px;
    }
  }
}