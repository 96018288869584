.uploader {
    position: relative;
  
    &__container {
      display: flex;
      justify-content: space-between;
    }
  
    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 35%;
      margin-bottom: 2rem;
      padding: .7rem 2rem;
      transition: color .3s;
      border: 2px solid #202025;
      border-radius: 4px;
      background: transparent;
      color: #202025;
      font-size: .75rem;
      font-weight: 800;
      letter-spacing: .17em;
      line-height: 24px;
      text-transform: uppercase;
      box-shadow: 0 5px 10px 8px rgba(0, 0, 0, .05);
      cursor: pointer;
      overflow: hidden;
      box-sizing: border-box;
  
      @media (min-width: 768px) {
        width: max-content;
      }
  
      &::after {
        position: absolute;
        top: 100%;
        width: 50%;
        height: 50%;
        transition: all .3s;
        border-radius: 50%;
        content: '';
        z-index: -1;
      }
  
      &:hover {
        &::after {
          top: -50%;
          width: 200%;
          height: 200%;
        }
      }
  
      &::before {
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110%;
        height: 110%;
        background-color: #f7fbfa;
        content: '';
        z-index: -1;
      }
  
      &::after {
        background-color: #202025;
        z-index: -1;
      }
  
      &:hover {
        color: #f7fbfa;
      }
    }
  
    &__btn_inAccount {
      color: #f7fbfa;
      cursor: pointer;
      z-index: 1;
  
      &::before {
        background-color: #202025;
      }
  
      &::after {
        width: 100%;
        height: 100%;
        background-color: #f7fbfa;
      }
  
      &:hover {
        color: #202025;
      }
    }
  
    &__input {
      position: absolute;
      margin-bottom: 2rem;
      padding: 1rem;
      font-size: 1.1rem;
      font-weight: 700;
      letter-spacing: 1.5px;
      opacity: 0;
      visibility: hidden;
    }
  
    &__label {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 60%;
      margin-bottom: 2rem;
      border-radius: .3rem;
      font-size: 1.1rem;
      font-weight: 700;
      letter-spacing: 1.5px;
      box-shadow: 0 5px 10px 8px rgba(0, 0, 0, .05);
      cursor: pointer;
  
      &:hover {
        background-color: #f7fbfa;
        color: #202025;
      }
  
      @media (min-width: 420px) {
        padding: 1rem;
      }
  
      @media (min-width: 768px) {
        width: max-content;
      }
    }
  
    &__fileName {
      margin-bottom: 32px;
    }
  
    svg {
      display: none;
    }
  
    @media (min-width: 501px) {
      svg {
        display: block;
      }
    }
  
    .label {
      display: inline-block;
      width: 100%;
      margin-bottom: .5rem;
      font-size: 1.1rem;
    }
  
    .input {
      width: 100%;
      margin-bottom: 2rem;
      padding: 1rem;
      border-radius: .3rem;
      font-size: 1.1rem;
      font-weight: 700;
      letter-spacing: 1.5px;
      box-shadow: 0 5px 10px 8px rgba(0, 0, 0, .05);
  
      &::placeholder {
        color: #888;
      }
  
      &:focus {
        border-color: transparent;
        outline: 2px solid #202025;
      }
  
      &:has(+.error) {
        outline: 2px solid red;
        box-shadow: 0 5px 10px 8px rgba(255, 0, 0, .05);
      }
    }
  
    &__successMessage {
      position: absolute;
      top: 22%;
      left: 50%;
      width: max-content;
      // padding: 8px 16px;
      transform: translateX(-50%);
      border: 2px solid #202025;
      border-radius: 4px;
      background-color: #f7fbfa;
      color: #202025;
      font-size: 14px;
      font-weight: 600;
      pointer-events: none;
      z-index: 1;
    }
  
    @media (min-width: 501px) {
      &__successMessage {
        top: 27%;
      }
    }
  
    @media (min-width: 920px) {
      &__successMessage {
        top: 5%;
        left: 55%;
        padding: 9px;
      }
    }
  
    // &__successMessage_inAccount {
    //   top: 75%;
    // }
  }