.list {
  list-style: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style-position: inside;
  margin-bottom: 15px;
}

.listItem {
  box-sizing: content-box;
  // margin-left: 10px;
}

.sublist {
  list-style: inside;
  // text-indent: 1em;
}

.deliveryList {
  list-style: square;
  list-style-position: inside;
  // text-indent: 2em;
}

.deliverySubList li::before {
  content: "- ";
}

  // .ulListItem {
  //   // text-indent: 3em;
  // }