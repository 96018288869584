$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;

.adminParams {
    // width: 60%;
    margin: 75px auto 0;
    box-sizing: border-box;

    &__titleItems {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 48px;
        font-weight: 800;
        text-transform:capitalize;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: max-content;
        padding: 1rem;
        transition: color .3s;
        border: 2px solid #202025;
        border-radius: 4px;
        background: transparent;
        color: #f7fbfa;
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        overflow: hidden;
        z-index: 1;
    
        &::before {
          position: absolute;
          top: -5%;
          left: -5%;
          width: 110%;
          height: 110%;
          background-color: #202025;
          content: '';
          z-index: -1;
        }
    
        &::after {
          position: absolute;
          top: 100%;
          width: 100%;
          height: 100%;
          transition: all .3s;
          border-radius: 50%;
          background-color: #f7fbfa;
          content: '';
          z-index: -1;
        }
    
        &:hover {
          color: #202025;
        }
    
        &:hover {
          &::after {
            top: -50%;
            width: 200%;
            height: 200%;
          }
        }
      }

      &__btnDisabled {
        color: #202025;
  
        &::before {
          background-color: $color-grey;
        }
  
        &::after {
          background-color: $color-grey;
        }
      }

    &__nav {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    &__table {
      width: 500px;
      border-collapse: collapse;
      border: 2px solid $color-dark;
      font-size: 1.1rem;
      letter-spacing: 1.5px;
      text-align: left;
    }

    // Кастомний чекбокс
  &__table input[type="checkbox"] {
    /* Приховую оригінальный чекбокс */
    position: relative;
    margin-right: 10px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__table input[type="checkbox"]::before {
    display: inline-block;
    width: 22px;
    height: 22px;
    transition: background-color .2s ease, border-color .2s ease;
    border: 2px solid black;
    border-radius: 4px;
    background-color: white;
    content: "";
    cursor: pointer;
  }

  &__table input[type="checkbox"]:checked::before {
    border-color: black;
    background-color: black;
  }

  &__table input[type="checkbox"]::after {
    position: absolute;
    top: 1px;
    left: 7px;
    width: 8px;
    height: 15px;
    transform: rotate(45deg) scale(0);
    transition: transform .2s ease, opacity .2s ease;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    content: "";
    opacity: 0;
  }

  &__table input[type="checkbox"]:checked::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
  }

  &__table th {
    background-color: $color-grey;
    padding: 10px;
  }

  &__table td {
    padding: 10px;
    border: 2px solid $color-dark;
  }

  &__table button {
    cursor: pointer;
  }

  &__editItems {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__editItemsInput {
    font-size: 1.1rem;
  }

}