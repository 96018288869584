$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;
$color-accent: #f92e3e;

.form {
  flex: 0 1 60%;
  width: 100%;
  // order: -1;
  padding: 1.5rem 1rem;
  background-color: $color-light;
  border-radius: 1rem;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;

  @media (min-width: 768px) {
    // order: unset;
  }

  &__title {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  form {
    max-width: 100%;

    label:has(+input[type='checkbox']),
    label:has(+input[type='radio']) {
      &::selection {
        background: none;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        transition: all .3s;
      }
    }

    label:has(+input[type='checkbox']) {
      position: relative;
      padding: 5px 0 5px 55px;
      width: max-content;
      margin-bottom: 1.5rem;
      cursor: pointer;

      &:before {
        top: 0;
        left: 0;
        width: 50px;
        height: 26px;
        border-radius: 13px;
        background: $color-grey-light;
        box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
      }

      &:after {
        top: 1px;
        left: 2px;
        width: 22px;
        height: 22px;
        border-radius: 10px;
        background: $color-light;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
      }
    }

    label:has(+input[type='checkbox']:checked) {
      &:before {
        background: $color-accent;
      }

      &:after {
        left: 26px;
      }
    }

    label:has(+input[type='radio']) {
      position: relative;
      padding-left: 55px;
      line-height: 2.5rem;
      width: max-content;
      margin-bottom: 1.5rem;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-right: 2.5rem;
      }

      &:has(+input[type='radio']:checked)::before {
        background: $color-accent;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
      }

      &:before {
        top: 0;
        left: 0;
        width: 2.5rem;
        height: 2.5rem;
        background: $color-grey-light;
        box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
      }

      &:hover::before {
        background: $color-grey;
      }

      &:after {
        top: calc(2.5rem / 2);
        left: calc(2.5rem / 2);
        transform: translate(-50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
        background: $color-light;
        box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
      }

      &:active::after {
        box-shadow: inset 0 2px 3px rgba(0, 0, 0, .4);
      }
    }

    input,
    label+div>input {
      margin-bottom: 1rem;
      border: none;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);

      &[type='checkbox'],
      &[type='radio'] {
        width: 0;
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
    }

    label+div>input {
      margin-bottom: 0;
    }

    div:has(>input) {
      margin-bottom: 2rem;
    }

    button {
      border: 2px solid $color-dark;
      background-color: $color-dark;
      color: $color-light;
      z-index: 1;

      &::after {
        background-color: $color-light;
      }

      &::before {
        background-color: $color-dark;
      }

      &:hover {
        color: $color-dark;
      }
    }

    @media (min-width: 1024px) {

      input {
        margin-bottom: 2rem;
      }
    }
  }
}