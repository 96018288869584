html:has(.productDetails),
body:has(.productDetails) {
  height: 100%;
}

.productDetails {
  display: flex;
  align-items: center;
  height: 100%;
  
  &__container {
    min-width: 320px;
    max-width: 1470px;
    // padding: 0 15px;
    padding: 7rem 15px 4rem;
    margin: 0 auto;
    box-sizing: border-box;
  }
}