.PhoneInputInput {
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: .3rem;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  box-shadow: 0 5px 10px 8px rgba(214, 9, 9, 0.05);
  box-sizing: border-box;
}

.form {
  width: 100%;
  max-width: 37rem;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;

  &__label {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: .5rem;
    font-size: 0.9rem;

    &:has(>.form__error)+.form__input {
      outline: 2px solid red;
      border: transparent;
      box-shadow: 0 5px 10px 8px rgba(255, 0, 0, .05);
    }
  }

  &__input,
  &__label+div>input {
    width: 100%;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: .3rem;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    box-shadow: 0 5px 10px 8px rgba(214, 9, 9, 0.05);
    box-sizing: border-box;

    &::placeholder {
      color: #888;
    }

    &:focus {
      border-color: transparent;
      outline: 2px solid #202025;
    }

    &:has(+div) {
      margin-bottom: .3rem;

      &+div {
        margin-bottom: 1rem;
      }
    }

    &:has(+.form__error) {
      outline: 2px solid red;
      border: transparent;
      box-shadow: 0 5px 10px 8px rgba(255, 0, 0, .05);
    }
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: .7rem 2rem;
    transition: color .3s;
    border: 2px solid #f92e3e;
    border-radius: 4px;
    background: transparent;
    color: #f92e3e;
    font-size: .75rem;
    font-weight: 800;
    letter-spacing: .17em;
    line-height: 24px;
    text-transform: uppercase;
    box-shadow: 0 5px 10px 8px rgba(0, 0, 0, .05);
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;

    @media (min-width: 768px) {
      width: max-content;
    }

    &::after {
      position: absolute;
      top: 100%;
      width: 50%;
      height: 50%;
      transition: all .3s;
      border-radius: 50%;
      content: '';
      z-index: -1;
    }

    &:hover {
      &::after {
        top: -50%;
        width: 200%;
        height: 200%;
      }
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 110%;
      height: 110%;
      background-color: #f7fbfa;
      content: '';
      z-index: -1;
    }

    &::after {
      background-color: #f92e3e;
      z-index: -1;
    }

    &:hover {
      color: #f7fbfa;
    }
  }

  &__error {
    font-size: .8rem;
    color: red;

    @media (min-width: 1024px) {
      position: absolute;
      top: 85px;
      margin-bottom: 0;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}