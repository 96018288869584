$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;
$color-grey-light: #e3e3e3;

.selector {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-bottom: 1rem;

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .63rem;
    padding: .63rem 1rem;
    transition: .3s;
    border-radius: 4px;
    text-align: center;
    font-size: .75rem;
    line-height: 1.5rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;

    &:disabled {
      background: $color-grey;
      pointer-events: none;
    }

    & path {
      fill: $color-light;
      transition: fill .3s;
    }

    @media (min-width: 768px) {
      font-size: 1rem;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: -20%;
      width: 150%;
      height: 50%;
      border-radius: 50%;
      transition: all .3s;
      content: '';
      z-index: -1;
    }

    &::before {
      position: absolute;
      top: -5%;
      left: -5%;
      width: 150%;
      height: 150%;
      content: '';
      z-index: -1;
    }

    &:hover {
      color: $color-light;
    }

    &:hover {
      & path {
        fill: $color-dark;
      }

      &::after {
        top: -50%;
        width: 150%;
        height: 200%;
      }
    }
  }

  &__list {
    // position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    left: 0;
    top: 0;
    padding: 10px;
    width: 100%;
    max-height: 120px;
    border-radius: 10px;
    background: $color-grey-light;
    color: $color-grey-light;
    box-shadow: inset 0 3px 7px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    overflow: auto;
    z-index: 2;

    @media (min-width: 768px) {
      // left: 0;
      // top: 2rem;
      // width: max-content;
      // height: max-content;
    }
  }

  &__item {
    position: relative;
    padding: .44rem;
    // background: $color-grey !important;
    // border: none !important;
    border-radius: .63rem;
    font-size: .8rem;
    text-align: left;
    line-height: 1.5rem;
    cursor: pointer;
    transition: color .3s, background-color .3s;

    &:hover {
      background: $color-grey !important;
      color: $color-light !important;
    }
  }
}