.dropdown {
    width: 100%;
    position: relative;
  
    &__title {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  
    &__btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 10px 10px;
      border: 2px solid;
      border-radius: 4px;
      background-color: #fff;
  
      // &:active {
      //   border-color: #f92e3e;
      // }
  
      &_text {
        font-size: 13px;
        font-weight: 600;
        color: #9aa0ab;
      }
    }
  
    &__list {
      display: block;
      background-color: #fff;
      border: 2px solid black;
      border-radius: 5px;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.7);
      padding: 10px;
      position: absolute;
      transform: translateY(10px);
      width: 100%;
      z-index: 5;
      max-height: 330px;
      overflow-y: auto;
  
      // &.open {
      //   display: block;
      // }
    }
  
    &__item {
      font-size: 13px;
      text-transform: capitalize;
  
      & label {
        display: flex;
        align-items: center;
        padding: 8px 16px 5px 4px;
        transition: all .2s ease;
        cursor: pointer;
      }
    }
  
    // Кастомний чекбокс
    &__item input[type="checkbox"] {
      /* Приховую оригінальный чекбокс */
      position: relative;
      margin-right: 10px;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  
    &__item input[type="checkbox"]::before {
      display: inline-block;
      width: 20px;
      height: 20px;
      transition: background-color .2s ease, border-color .2s ease;
      border: 2px solid black;
      border-radius: 4px;
      background-color: white;
      content: "";
      cursor: pointer;
    }
  
    &__item input[type="checkbox"]:checked::before {
      border-color: black;
      background-color: black;
    }
  
    &__item input[type="checkbox"]::after {
      position: absolute;
      top: 1px;
      left: 7px;
      width: 8px;
      height: 15px;
      transform: rotate(45deg) scale(0);
      transition: transform .2s ease, opacity .2s ease;
      border-right: 3px solid white;
      border-bottom: 3px solid white;
      content: "";
      opacity: 0;
    }
  
    &__item input[type="checkbox"]:checked::after {
      transform: rotate(45deg) scale(1);
      opacity: 1;
    }
  }
  
  @media (min-width: 768px) {
    .dropdown {
      &__item {
        font-size: 15px;
      }
  
      &__btn_text {
        font-size: 15px;
      }
    }
  }
  
  @media (min-width: 1024px) {
    .dropdown {
      width: 100%;
      // width: calc(100% - 300px);
      // min-width: calc(30% - 100px);
    }
  }