$color-light: #f7fbfa;
$color-dark: #202025;
$color-grey: #5d5d5d;

.pagination {
  display: flex;
  align-items: center;
  gap: .5rem;

  &__text {
    font-size: 1rem;

    &_between {
      margin: 0 .3rem;
      font-size: .7rem;
    }
  }
}